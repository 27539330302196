/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react'
import { makeStyles, CircularProgress, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './MediaRenderization.style'
import api from '../../services/api'
import { base64ToBlob } from '../../utils/conversorFiles'
import { useSnackbar } from '../../shared/hooks'

const useStyles = makeStyles(styles)

const MediaRenderization = ({ file, onAttachmentLoaded, onMediaEndedCallback, lockButton }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const { showRequestError } = useSnackbar()
  const [loadCount, setLoadCount] = useState(0)
  const [attachmentCount, setAttachmentCount] = useState(-1)
  const incrementCounter = async () => {
    setLoadCount(loadCount + 1)
  }

  const playAttachmentsAndShowCardsByOrder = async () => {
    const audios = Array.from(document.getElementsByTagName('audio'))
    const videos = Array.from(document.getElementsByTagName('video'))

    if (videos.length > 0) {
      lockButton()
    }

    const audioAndVideos = videos.concat(audios)

    let offset = 2000
    audioAndVideos.forEach(audioOrVideo => {
      setTimeout(() => {
        if (!audioOrVideo.ended && !audioOrVideo.hasStarted) {
          // eslint-disable-next-line no-param-reassign
          const { style } = audioOrVideo.parentElement.parentElement.parentElement.parentElement
          style.transition = 'opacity 2s ease-out'
          style.visibility = 'visible'
          style.opacity = '1'

          audioOrVideo.play()
        }
      }, offset)

      offset += audioOrVideo.duration * 1000 + 5000
    })
  }

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    function videoEndingHandler(_e) {
      onMediaEndedCallback()
    }

    const videos = Array.from(document.getElementsByTagName('video'))
    videos.forEach(v => {
      v.addEventListener('ended', videoEndingHandler, false)
    })
  }, [loadCount, attachmentCount, onMediaEndedCallback])

  useEffect(() => {
    if (attachmentCount > 0 && loadCount === attachmentCount) {
      setIsLoading(false)
      playAttachmentsAndShowCardsByOrder()
    }
    if (file?.type.includes('image') && attachmentCount === -1) {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadCount, attachmentCount])

  const renderMedia = m => {
    if (m?.type.includes('image'))
      return (
        <img
          onLoad={onAttachmentLoaded}
          id="imageInstructionAndWelcome"
          key={m.name}
          alt={m.name}
          className={classes.img}
          src={URL.createObjectURL(m.media)}
        />
      )
    if (m?.type.includes('audio'))
      return (
        <audio
          id="audioInstructionAndWelcome"
          className={classes.audio}
          key={m.name}
          controls
          onLoadedData={() => {
            incrementCounter()
            onAttachmentLoaded()
          }}
        >
          <source src={URL.createObjectURL(m.media)} type={m.type} />
        </audio>
      )
    if (m?.type.includes('video'))
      return (
        <video
          id="videoInstructionAndWelcome"
          controls
          className={classes.video}
          onLoadedData={() => {
            incrementCounter()
            onAttachmentLoaded()
          }}
        >
          <source src={URL.createObjectURL(m.media)} type={m.type} />
        </video>
      )
    return <></>
  }

  useEffect(() => {
    const getMedia = async () => {
      setIsLoading(true)
      await api
        .get(`attachments/${file.id}`)
        .then(async resp => {
          const blob = await base64ToBlob(resp.data, file.type)
          // eslint-disable-next-line no-param-reassign
          file.media = new File([blob], file.name, {
            type: file.type,
          })
        })
        .catch(error => {
          const { detail } = error.response.data
          showRequestError(detail)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    getMedia()
    let attachmentCountTemp = 0
    attachmentCountTemp += file.type.indexOf('video') >= 0 || file.type.indexOf('audio') >= 0
    setAttachmentCount(attachmentCountTemp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <Grid>
      {isLoading ? (
        <Grid className={classes.progress}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container className={classes.container}>
          <Grid key="item" xs={12} className={classes.item}>
            {file.media ? renderMedia(file) : <></>}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

MediaRenderization.propTypes = {
  file: PropTypes.object,
}

MediaRenderization.defaultProps = {
  file: {},
}

export default React.memo(MediaRenderization)
