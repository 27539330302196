import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import StepComponent from './steps/StepComponent'
import styles from './PartnershipCollaboratorAssessmentForm.style'
import ReportGenerationDialog from '../../../components/ReportGenerationDialog'
import AuthContext from '../../../contexts/AuthContext'
import ApplicationContextProvider from '../../../contexts/ApplicationContext'
import { useResponsiveInfo } from '../../../shared/hooks'
import CloseAssessmentModal from './components/CloseAssessmentModal/CloseAssessmentModal'

const useStyles = makeStyles(styles)

const PartnershipCollaboratorAssessmentForm = ({
  history,
  assessmentPartnershipCollaborator,
  researcherAssessment,
  getInstrument,
}) => {
  const [activeStep, setActiveStep] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [showCloseModalDialog, setShowCloseModalDialog] = useState(false)
  const classes = useStyles()
  const { currentUser } = useContext(AuthContext)
  const [reportGenerationDialog, setReportGenerationDialog] = useState(false)
  const isSennaKids = currentUser?.codExaminee !== undefined
  const { isDesktop } = useResponsiveInfo()

  useEffect(() => {
    setActiveStep(assessmentPartnershipCollaborator.step)
    setIsLoading(false)
  }, [assessmentPartnershipCollaborator])

  function getStepContent(step) {
    return (
      <ApplicationContextProvider>
        <StepComponent
          step={step}
          researcherAssessment={researcherAssessment}
          history={history}
          getInstrument={getInstrument}
          assessmentPartnershipCollaborator={assessmentPartnershipCollaborator}
          closeApplication={() => {
            // document.getElementById('modalExitAssessment').requestFullscreen()
            setShowCloseModalDialog(true)
          }}
        />
      </ApplicationContextProvider>
    )
  }

  return (
    <>
      {isLoading ? (
        <Grid container spacing={3} className={classes.root}>
          <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
        </Grid>
      ) : (
        <Grid
          style={{
            width: '100%',
            height: '100%',
            marginTop: isSennaKids && !isDesktop ? '100px' : '',
          }}
        >
          {/* <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            item
            xs={12}
            className={!isDesktop ? classes.stepsContainerMobile : classes.stepsContainer}
          >
            <Grid
              item
              xs={6}
              direction="row"
              className={`${classes.greetingSubtitle} ${classes.topItem}`}
            >
              <Grid container justifyContent="center">
                <Grid xs={12} item style={{ display: 'contents' }}>
                  <AccountCircle fontSize="large" />
                  <Typography variant="h6">{UserService.getName()}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} className={classes.topItem} style={{ textAlign: 'right' }}>
              <HighlightOff
                fontSize="large"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  document.getElementById('modalExitAssessment').requestFullscreen()
                  setShowCloseModalDialog(true)
                }}
              />
            </Grid>
          </Grid> */}
          {getStepContent(activeStep)}
          <ReportGenerationDialog
            open={reportGenerationDialog}
            handleClose={() => setReportGenerationDialog(false)}
          />
          <CloseAssessmentModal
            open={showCloseModalDialog}
            handleClose={() => {
              setShowCloseModalDialog(false)
            }}
            onExitAssessment={() => {
              localStorage.removeItem('initialDateTimeSessionTemp')
              history.push('/aplicacoes-respondente')
            }}
          />
        </Grid>
      )}
    </>
  )
}

PartnershipCollaboratorAssessmentForm.propTypes = {
  researcherAssessment: PropTypes.object.isRequired,
}

export default withStyles(styles)(PartnershipCollaboratorAssessmentForm)
