/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { AccountCircle } from '@material-ui/icons'
import styles from './ResearcherInstructionAndWelcome.style'
import MediaRenderization from '../MediaRenderization'
import { getSanitizedHtmlString } from '../../utils/SanitizeHtml'
import { useResponsiveInfo } from '../../shared/hooks'
import close from './img/close.svg'
import UserService from '../../services/UserService'

const useStyles = makeStyles(styles)

const ResearcherInstructionAndWelcome = ({
  attachment,
  title,
  description,
  dimissInstruction,
  isWelcome,
  onClose = () => {},
  id,
}) => {
  const classes = useStyles()
  const { isTablet, isMobile, getResponsivePadding } = useResponsiveInfo()

  const [blockInitButton, setBlockInitButton] = useState(false)

  const unlockButton = () => setBlockInitButton(false)
  const lockButton = () => setBlockInitButton(true)

  const reloadButton = () => {
    const button = document.getElementById('buttonInit')
    button.style.visibility = 'visible'
  }

  return (
    <Grid>
      <Grid
        id="researcherInstructionAndWelcomeDialog"
        keepMounted
        maxWidth={false}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ alignContent: 'center' }}
        s
      >
        <Grid
          container
          style={{
            paddingLeft: getResponsivePadding(),
            paddingRight: getResponsivePadding(),
          }}
        >
          <Grid
            item
            xs={12}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Grid>
              <Typography className={classes.title}>{title}</Typography>
            </Grid>
            {!isWelcome && (
              <Grid style={{ display: 'flex', alignItems: 'center' }} onClick={onClose}>
                {isTablet && !isMobile && (
                  <Typography style={{ color: '#003C78', marginRight: 5, fontWeight: 700 }}>
                    FECHAR AVALIAÇÃO
                  </Typography>
                )}
                <img src={close} alt="close-action" style={{ cursor: 'pointer' }} />
              </Grid>
            )}
          </Grid>
          {!isWelcome && (
            <Grid item xs={12} direction="row" style={{ color: '#003C78', marginBottom: 25 }}>
              <Grid container justifyContent="flex-start">
                <Grid xs={12} item style={{ display: 'contents' }}>
                  <AccountCircle fontSize="large" />
                  <Typography
                    style={{ fontSize: 18, color: '#003C78', fontWeight: 'bold', marginTop: 5 }}
                  >{`Olá, ${UserService.getPartnershipCollaboratorName()}!`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {description?.length <= 360 ? (
            <Grid className={classes.text} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: getSanitizedHtmlString(description) }} />
            </Grid>
          ) : (
            <Grid className={classes.textSmall} xs={12}>
              <div dangerouslySetInnerHTML={{ __html: getSanitizedHtmlString(description) }} />
            </Grid>
          )}
          <Grid item className={classes.media} xs={12}>
            {attachment && (
              <MediaRenderization
                file={attachment}
                onMediaEndedCallback={unlockButton}
                lockButton={lockButton}
                onAttachmentLoaded={reloadButton}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: !isWelcome && !isMobile && isTablet ? 'center' : 'flex-start',
            }}
          >
            <Button
              id="buttonInit"
              className={classes.button}
              style={{ visibility: attachment ? 'hidden' : 'visible' }}
              onClick={() => {
                dimissInstruction(id)
              }}
              disabled={attachment && blockInitButton}
            >
              Começar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ResearcherInstructionAndWelcome.propTypes = {
  attachment: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default React.memo(ResearcherInstructionAndWelcome)
