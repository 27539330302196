/* eslint-disable no-unused-expressions */
import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { Checkbox, TextField } from '@material-ui/core'

import { matchSorter } from 'match-sorter'

const MultiSelect = ({
  items,
  selectedValues,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onToggleOption,
  onClearOptions,
  onSelectAll,
  getOptionLabel,
  disabled,
  keyFilterField,
  error,
  helperText,
  showSelectAll,
  forcePopupIcon,
  orderField,
  chip,
  InputLabelProps,
}) => {
  const allSelected = items.length === selectedValues.length

  const handleToggleSelectAll = () => {
    onSelectAll && onSelectAll(!allSelected)
  }

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      if (selectedOptions.find(option => option.value === 'select-all')) {
        handleToggleSelectAll()
      } else {
        onToggleOption && onToggleOption(selectedOptions)
      }
    } else if (reason === 'clear') {
      onClearOptions && onClearOptions()
    }
  }

  const optionRenderer = (option, { selected }) => {
    if (option.value === 'noValue') {
      return <>{getOptionLabel(option)}</>
    }
    const selectAllProps = option.value === 'select-all' ? { checked: allSelected } : {}
    return (
      <>
        <Checkbox checked={selected} {...selectAllProps} />
        {getOptionLabel(option)}
      </>
    )
  }

  const getOptionSelected = (option, anotherOption) => option.name === anotherOption.name

  const filterOptions = (opt, { inputValue }) =>
    matchSorter(opt, inputValue, {
      baseSort: (a, b) =>
        a.item[orderField ?? keyFilterField] < b.item[orderField ?? keyFilterField] ? -1 : 1,
      keys: [{ threshold: matchSorter.rankings.CONTAINS, key: keyFilterField }],
    })

  return (
    <Autocomplete
      multiple
      freeSolo
      clearOnBlur
      limitTags={limitTags}
      options={items}
      value={selectedValues}
      disableCloseOnSelect
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      forcePopupIcon={forcePopupIcon}
      getOptionSelected={getOptionSelected}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        const filtered = filterOptions(options, params)
        if (filtered.length === 0) {
          return [{ name: 'Não encontramos nenhum resultado', value: 'noValue' }]
        }
        if (options.length > 0 && showSelectAll) {
          return [{ name: selectAllLabel, value: 'select-all' }, ...filtered]
        }
        return [...filtered]
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderTags={chip}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          InputLabelProps={InputLabelProps}
        />
      )}
    />
  )
}

MultiSelect.defaultProps = {
  limitTags: 4,
  items: [],
  selectedValues: [],
  getOptionLabel: value => value,
}

export default MultiSelect
