export default isMobile => ({
  content: {
    overflow: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  contentMobile: {
    overflow: 'none',
  },
  button: {
    width: 100,
  },
  progress: {
    marginTop: '1rem',
    backgroundColor: '#fff',
  },
  titleStep: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '2.5vh',
    color: '#003C78',
  },
  introStep: {
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  buttonBack: {
    width: 100,
    fontWeight: '800',
  },
  buttonNext: {
    width: 100,
    fontWeight: '800',
    backgroundColor: '#ffca00',
    border: 'none',
    Color: '#FCFCFC',
    boxShadow:
      // eslint-disable-next-line max-len
      '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#ffca00',
      border: 'none',
    },
  },
  hiddenRadio: {
    display: 'none',
  },
  externalDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alternative: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '5px',
    borderRadius: '8px',
    marginRight: '10px',
    marginLeft: '10px',
    padding: '12px',
    marginTop: '10px',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alternativeButton: {
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: 'white !important',
    '&:hover': {
      backgroundColor: '#25b814 !important',
      color: 'white',
    },
  },
  childAnswer: {
    fontWeight: 'bold',
    fontStyle: 'bold',
    color: '#363636',
  },
  childAnswerGroup: {
    width: '97%',
    justifyContent: 'center',
    padding: '0px 10px 10px 10px',
  },
  checked: {
    // Style for when the Radio button is checked
    color: '#003C78 !important', // The color you want for the checked (inner circle) state
    '&.Mui-checked': {
      // Ensuring the inner circle has the correct color
      color: '#FFF !important',
    },
  },
  menuItem: {
    borderBottom: '1px solid #858585',
    color: '#858585',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: !isMobile ? '#B1E9FF !important' : 'transparent',
      color: !isMobile ? '#003C78 !important' : '#858585',
      fontWeight: !isMobile ? 600 : 400,
      borderBottom: !isMobile ? '0px solid #858585' : '1px solid #858585',
    },
    '&.Mui-selected': {
      backgroundColor: '#B1E9FF !important',
      color: '#003C78 !important',
      fontWeight: 600,
    },
  },
})
