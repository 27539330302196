/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useContext, useRef } from 'react'
import { makeStyles, CircularProgress, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import api from '../../../../../services/api'
import { useResponsiveInfo, useSnackbar } from '../../../../../shared/hooks'
import { base64ToBlob } from '../../../../../utils/conversorFiles'
import styles from './AttachmentVisualization.style'
import { AuthContext } from '../../../../../contexts'

const useStyles = makeStyles(styles)

const AttachmentVisualization = ({ item, itemType, incrementCounter, answerOptionsSize }) => {
  const classes = useStyles()
  const { currentUser } = useContext(AuthContext)
  const { isMobile } = useResponsiveInfo()
  const isSennaKids = currentUser?.codExaminee !== undefined
  const [isLoading, setIsLoading] = useState(true)
  const { showRequestError } = useSnackbar()
  const mediaRef = useRef(null)
  const hasMediaToload = item.attachments?.filter(
    file => file.type.includes('audio') || file.type.includes('video'),
  )

  const isPuppetWithChildren = item?.answerKeyChildren?.type === 'PUPPETS'

  const checkDataLoaded = () => {
    if (mediaRef.current && mediaRef.current.readyState === 1) {
      mediaRef.current.load()
      setTimeout(checkDataLoaded, 500)
    } else if (hasMediaToload) {
      setTimeout(checkDataLoaded, 500)
    }
  }

  useEffect(() => {
    checkDataLoaded()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAttachments = async (it, attachments) => {
    const files = []
    for (const attach of attachments) {
      // eslint-disable-next-line no-await-in-loop
      await api
        .get(`attachments/${attach.id}`)
        .then(async resp => {
          const blob = await base64ToBlob(resp.data, attach.type)
          files.push(
            new File([blob], attach.name, {
              type: attach.type,
            }),
          )
        })
        .catch(error => {
          const { detail } = error.response.data
          showRequestError(detail)
        })
      // eslint-disable-next-line no-param-reassign
      it.files = files
    }
  }

  useEffect(() => {
    if (itemType === 'answer') {
      const getAnswerKey = async () => {
        if (item.id && item.attachments) {
          setIsLoading(true)
          await getAttachments(item, item.attachments)
            .catch(error => {
              const { detail } = error.response.data
              showRequestError(detail)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      }
      getAnswerKey()
    } else if (itemType === 'question') {
      const getQuestion = async () => {
        if (item.id && item.notebookItemAttachments) {
          const attachments = []
          item.notebookItemAttachments.forEach(notebookItem => {
            attachments.push(notebookItem.attachment)
          })
          setIsLoading(true)
          await getAttachments(item, attachments)
            .catch(error => {
              const { detail } = error.response.data
              showRequestError(detail)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      }
      getQuestion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, showRequestError])

  const renderAttachment = file => {
    if (file.type.includes('image'))
      return (
        <img
          id={`${itemType}ImageAttachment${file.name}`}
          key={file.name}
          alt={file.name}
          className={
            (itemType === 'question' && classes.imgQuestion) ||
            (itemType !== 'question' &&
              answerOptionsSize !== 4 &&
              (isMobile ? classes.imgMobile : classes.img)) ||
            (answerOptionsSize === 4 && classes.widthAttachmentEmotionalIntelligence)
          }
          src={URL.createObjectURL(file)}
        />
      )
    if (file.type.includes('audio'))
      return (
        <audio
          ref={mediaRef}
          id={`${itemType === 'question' ? '1' : '2'}AudioAttachment${file.name}`}
          className={
            (itemType === 'question' && classes.audioQuestion) ||
            (itemType !== 'question' && answerOptionsSize !== 4 && classes.audio) ||
            (answerOptionsSize === 4 && classes.widthAttachmentEmotionalIntelligence)
          }
          key={file.name}
          controls
          onLoadedData={incrementCounter}
        >
          <source src={URL.createObjectURL(file)} type={file.type} />
        </audio>
      )
    if (file.type.includes('video'))
      return (
        <video
          id={`${itemType === 'question' ? '1' : '2'}VideoAttachment${file.name}`}
          ref={mediaRef}
          controls
          className={
            (itemType === 'question' && classes.videoQuestion) ||
            (itemType !== 'question' && answerOptionsSize !== 4 && classes.video) ||
            (answerOptionsSize === 4 && classes.widthAttachmentEmotionalIntelligence)
          }
          onLoadedData={incrementCounter}
        >
          <source src={URL.createObjectURL(file)} type={file.type} />
        </video>
      )
    return <></>
  }

  return (
    <div className={itemType === 'question' && classes.externalDiv}>
      {isLoading ? (
        <Grid className={classes.progress}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container xs={12} spacing={3} className={classes.container}>
          <Grid
            key="item"
            item
            xs={12}
            style={{
              margin: itemType === 'answer' ? '0.5rem' : '',
              marginBottom: itemType === 'answer' ? 0 : 20,
            }}
            className={isSennaKids || isPuppetWithChildren ? classes.itemSennaKids : classes.item}
          >
            {item.files?.map(file => {
              return <>{renderAttachment(file)}</>
            })}
          </Grid>
        </Grid>
      )}
    </div>
  )
}

AttachmentVisualization.propTypes = {
  item: PropTypes.object,
}

AttachmentVisualization.defaultProps = {
  item: {},
}

export default React.memo(AttachmentVisualization)
