import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Header from './Header'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appbar: {
    background: '#003C78',
    height: '14px',
    boxShadow: 'none',
  },
  toolbar: {
    minHeight: '14px',
  },
}))

export default function Navbar() {
  const classes = useStyles()

  return (
    <AppBar position="fixed" className={classes.root}>
      <Header />
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar} />
      </AppBar>
    </AppBar>
  )
}
