export default theme => ({
  container: {
    opacity: '1',
    display: 'table-caption',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#003C78',
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#003C78',
  },

  imgMobile: {
    maxWidth: '200px',
    maxHeight: '700px',
  },
  img: {
    maxWidth: '400px',
    maxHeight: '700px',
  },
  imgQuestion: {
    display: 'block',
    maxWidth: '400px',
    maxHeight: '300px',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  video: {
    width: '100%',
    height: '100%',
    marginRight: '5px',
  },
  videoQuestion: {
    display: 'block',
    maxWidth: '400px',
    maxHeight: '300px',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  audio: {
    width: '100%',
  },
  widthAttachmentEmotionalIntelligence: {
    width: 'calc(270px - 15px)',
  },
  audioQuestion: {
    width: '100%',
    maxWidth: '400px',
    maxHeight: '300px',
    objectFit: 'contain',
    marginBottom: '8px',
  },
  progress: {
    textAlign: 'center',
    marginTop: '150px',
  },
  itemSennaKids: {
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    borderRadius: '8px',
    margin: '0.5rem',
    width: '100%',
  },
  item: {
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    borderRadius: '8px',
    width: '100%',
    display: 'flex',
  },
  icon: {
    color: '#A6A6A6',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  textItem: {
    display: 'flex',
  },
  textItemChildren: {
    textAlign: 'center',
    padding: '4px',
  },
  externalDiv: {
    textAlign: '-webkit-center',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
})
