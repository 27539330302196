import React from 'react'
import { withStyles, ListItem, ListItemAvatar, Typography, ListItemText } from '@material-ui/core'
import { AccountCircleOutlined } from '@material-ui/icons'
import styles from './style'
import userSeelcted from '../../icons/user_selected.svg'
import user from '../../icons/perfil.svg'

const UserLogged = ({
  route,
  active,
  classes,
  history,
  collapsed,
  userName,
  userProfile,
  group,
  handleClick = () => {},
}) => {
  return (
    <ListItem
      component="button"
      className={`${classes.listItem} ${collapsed && classes.listItemCollapsed}`}
      onClick={() => {
        history.push(`/${route}`)
        handleClick(route)
      }}
    >
      <div className={`${classes.item} ${classes.division} ${active && classes.divisionActive}`}>
        <ListItemAvatar>
          {!collapsed ? (
            <img
              alt=""
              src={active ? userSeelcted : user}
              className={`${!collapsed && classes.iconAvatar} ${collapsed &&
                classes.listItemCollapsed}`}
            />
          ) : (
            <AccountCircleOutlined
              className={`${!collapsed && classes.iconAvatar} ${collapsed &&
                classes.listItemCollapsed}`}
            />
          )}
        </ListItemAvatar>
        {!collapsed && (
          <ListItemText
            primary={
              <Typography variant="body1" className={classes.userLoggedName}>
                {userName}
              </Typography>
            }
            secondary={
              <Typography component="span" className={classes.item} color="textPrimary">
                {group === 'Implementador' ? group : userProfile}
              </Typography>
            }
          />
        )}
      </div>
    </ListItem>
  )
}

export default withStyles(styles)(UserLogged)
