import React from 'react'
import { Typography, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { hotjar } from 'react-hotjar'
import SennaOld from '../../img/logoOld.svg'

import styles from './FooterLoggedIn.style'
import { useResponsiveInfo } from '../../../../shared/hooks'

const useStyles = makeStyles(styles)

const FooterLoggedIn = ({ history, isLoggedIn }) => {
  const classes = useStyles()
  const { isDesktop } = useResponsiveInfo()

  return (
    <Grid className={classes.footerLogged}>
      <Grid
        item
        xs={!isDesktop ? 9 : 12}
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
        style={{ marginTop: !isDesktop ? '2%' : '', paddingLeft: !isDesktop ? '' : '273px' }}
      >
        <Grid item xs="auto" md="auto">
          <img alt="Logo Senna" src={SennaOld} className={classes.logo} />
        </Grid>

        <Grid item xs="auto" md="auto" style={{ width: !isDesktop ? '100%' : '' }}>
          <Typography variant="body1" className={classes.copyright}>
            Copyright © 2019 - Instituto Ayrton Senna | Todos os direitos reservados.
          </Typography>
          {!window.location.href.includes('homologacao')
            ? hotjar.initialize(1849207, 6)
            : hotjar.initialize(1849191, 6)}
        </Grid>

        <Grid item xs="auto" md="auto">
          <Button
            variant="outlined"
            color="primary"
            className={classes.termsAndPolicy}
            onClick={() => {
              history.push({
                pathname: '/termos',
                isLoggedIn: { isLoggedIn },
              })
            }}
          >
            Termos de Uso
          </Button>
        </Grid>

        <Grid item xs="auto" md="auto">
          <Button
            variant="outlined"
            color="primary"
            className={classes.termsAndPolicy}
            onClick={() => {
              history.push({
                pathname: '/politicas',
                isLoggedIn: { isLoggedIn },
              })
            }}
          >
            Política de Privacidade
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FooterLoggedIn
